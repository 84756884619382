import AppException from '../exceptions/App.exception';

type TObjectStoreConfig = {
  name: string;
  keyPath: string;
  autoIncrement?: boolean;
};

export const openDB = async (
  dbName: string,
  version: number,
  objectStores: TObjectStoreConfig[],
): Promise<IDBDatabase> => {
  function promisifyIDBOpenRequest(request: IDBOpenDBRequest): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result as IDBDatabase);
    });
  }

  function createObjectStores(db: IDBDatabase, objectStores: TObjectStoreConfig[]) {
    objectStores.forEach(store => {
      if (!db.objectStoreNames.contains(store.name)) {
        db.createObjectStore(store.name, {
          keyPath: store.keyPath,
          autoIncrement: store.autoIncrement ?? false,
        });
      }
    });
  }

  if (!indexedDB) {
    throw new Error('indexedDB is not supported');
  }

  try {
    const request = indexedDB.open(dbName, version);

    request.onupgradeneeded = () => {
      const db = request.result;

      createObjectStores(db, objectStores);
    };

    const db = await promisifyIDBOpenRequest(request);

    return db;
  } catch (error: any) {
    /**
     * We are throwing a custom error here, which we will choose not to send
     * to the server. This error arises only when indexedDB.open fails due to
     * fundamental reasons such as Disk Space issues, Browser Profile
     * problems, Browser Bugs or Limitations, Operating System Issues, or
     * Permission Issues. Additionally, we cannot fix this error.
     */
    throw new AppException({
      message: `Failed to open indexedDB, ${error.message}`,
      name: error.message.includes('indexedDB.open')
        ? AppException.ERROR_NAME.IndexedDBOpenError
        : undefined,
      type: AppException.ERROR_TYPE.IndexedDBError,
    });
  }
};

export const getObject = async <T>(
  db: IDBDatabase,
  storeName: string,
  key: IDBValidKey,
): Promise<T | null> => {
  try {
    const getObjectPromise = new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readonly');

      transaction.onerror = () => reject(transaction.error);

      const store = transaction.objectStore(storeName);
      const request = store.get(key);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result as T);
    });

    return (await getObjectPromise) as T;
  } catch (error: any) {
    throw new AppException({
      message: `Failed to retrieve the specified object, ${error.message}.`,
      type: AppException.ERROR_TYPE.IndexedDBError,
      details: {
        key,
        storeName,
      },
    });
  }
};

export const getObjects = async <T>(db: IDBDatabase, storeName: string): Promise<T> => {
  try {
    const getObjectsPromise = new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readonly');
      const store = transaction.objectStore(storeName);

      transaction.onerror = () => reject(transaction.error);

      if (store.getAll) {
        // Use getAll if available
        const request = store.getAll();

        request.onerror = () => reject(request.error);
        request.onsuccess = () => resolve(request.result as T);
      } else {
        // Fallback to openCursor if getAll is not available
        const results: any = [];
        const cursorRequest = store.openCursor();

        cursorRequest.onerror = () => reject(cursorRequest.error);
        cursorRequest.onsuccess = (event: Event) => {
          const cursor = (event.target as IDBRequest).result as IDBCursorWithValue;

          if (cursor) {
            results.push(cursor.value);
            cursor.continue();
          } else {
            resolve(results as T);
          }
        };
      }
    });

    return (await getObjectsPromise) as T;
  } catch (error: any) {
    throw new AppException({
      message: `Failed to retrieve the objects, ${error.message}.`,
      type: AppException.ERROR_TYPE.IndexedDBError,
      details: {
        storeName,
      },
    });
  }
};

export const upsertObject = async (
  db: IDBDatabase,
  storeName: string,
  object: any,
): Promise<void> => {
  try {
    const upsertObjectPromise = new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readwrite');

      transaction.onerror = () => reject(transaction.error);

      const store = transaction.objectStore(storeName);
      const request = store.put(object);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(undefined);
    });

    await upsertObjectPromise;
  } catch (error: any) {
    throw new AppException({
      message: `Failed to upsert object, ${error.message}.`,
      type: AppException.ERROR_TYPE.IndexedDBError,
      details: {
        object,
        storeName,
      },
    });
  }
};

export const deleteObject = async (
  db: IDBDatabase,
  storeName: string,
  key: IDBValidKey,
): Promise<void> => {
  try {
    const deleteObjectPromise = new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readwrite');

      transaction.onerror = () => reject(transaction.error);

      const store = transaction.objectStore(storeName);
      const request = store.delete(key);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(undefined);
    });

    await deleteObjectPromise;
  } catch (error: any) {
    throw new AppException({
      message: `Failed to delete object, ${error.message}.`,
      type: AppException.ERROR_TYPE.IndexedDBError,
      details: {
        storeName,
        key,
      },
    });
  }
};
