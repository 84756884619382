import { addQueryParamsToUrl } from '../libraries/url.library';
import { detectBrowser } from '../libraries/browser.library';
import { getTimezoneOffset } from '../libraries/dateTime.library';
import { sendHttpRequest } from '../libraries/httpRequest.library';
import AppException from '../exceptions/App.exception';
import { env } from '../config/constants.config';
import { throwDexterAPIError } from './helper.api';

export const sendNotificationViewedAnalytics = async ({ tag }: { tag: string }) => {
  const browserInfo = detectBrowser();

  const viewUrl = addQueryParamsToUrl(`${env.__ANALYTICS_API_ENDPOINT__}/notification/view`, {
    tag,
    swv: env.__WORKER_VERSION__,
    bv: browserInfo.version,
    tz: getTimezoneOffset(),
  });

  const response = await sendHttpRequest(viewUrl, {
    retries: 3,
  });

  if (!response.ok) {
    await throwDexterAPIError(response, "Couldn't send notification view analytics.");
  }
};

export const sendNotificationClickedAnalytics = async ({
  tag,
  action,
}: {
  tag: string;
  action: string;
}) => {
  const browserInfo = detectBrowser();

  const clickUrl = addQueryParamsToUrl(`${env.__ANALYTICS_API_ENDPOINT__}/notification/click`, {
    tag,
    action,
    swv: env.__WORKER_VERSION__,
    bv: browserInfo.version,
    tz: getTimezoneOffset(),
  });

  const response = await sendHttpRequest(clickUrl, {
    retries: 3,
  });

  if (!response.ok) {
    await throwDexterAPIError(response, "Couldn't send notification click analytics to server.");
  }
};

export const fetchPayloadLessNotifications = async (deviceToken: string): Promise<any[]> => {
  const browserInfo = detectBrowser();

  const url = addQueryParamsToUrl(`${env.__ANALYTICS_API_ENDPOINT__}/notification`, {
    swv: env.__WORKER_VERSION__,
    device: deviceToken,
    bv: browserInfo.version,
  });

  const response = await sendHttpRequest(url, {
    retries: 3,
  });

  if (!response.ok) {
    throw new AppException({
      message: `Failed to fetch push payload for device token ${deviceToken} with status ${response.status}.`,
      name: AppException.ERROR_NAME.PayloadLessFetchError,
      details: {
        status: response.status,
        url: response.url,
        origin: self.location.href,
      },
    });
  }

  let responseData;

  try {
    responseData = await response.json();
  } catch (error: any) {
    throw new AppException({
      message: `Parsing of the notification payload failed, ${error.message}`,
      name: AppException.ERROR_NAME.PayloadLessParseError,
      details: {
        status: response.status,
        url: response.url,
      },
    });
  }

  if (Array.isArray(responseData) && responseData.length) {
    return responseData;
  }

  throw new AppException({
    message: `Received empty/invalid payload less notification from device ${deviceToken}.`,
    name: AppException.ERROR_NAME.PayloadLessInvalidNotificationPayload,
    details: { responseData, url: response.url, status: response.status },
  });
};

export const sendNotificationViewedReferAnalytics = async ({ tag }: { tag: string }) => {
  const viewReferUrl = addQueryParamsToUrl(
    `${env.__ANALYTICS_API_ENDPOINT__}/notification/view/refer`,
    {
      tag,
    },
  );

  const response = await sendHttpRequest(viewReferUrl, {
    retries: 2,
  });

  if (!response.ok) {
    await throwDexterAPIError(
      response,
      "Couldn't send notification view refer analytics to server.",
    );
  }
};

export const fetchPayloadNotifications = async ({
  tag,
  postback,
}: {
  tag: string;
  postback: string;
}): Promise<any[]> => {
  const browserInfo = detectBrowser();

  const url = addQueryParamsToUrl(`${env.__ANALYTICS_API_ENDPOINT__}/notification/fetch`, {
    tag,
    swv: env.__WORKER_VERSION__,
    bv: browserInfo.version,
  });

  const response = await sendHttpRequest(url, {
    method: 'POST',
    body: JSON.stringify({
      tag,
      postback,
    }),
    retries: 3,
  });

  if (!response.ok) {
    throw new AppException({
      message: `Failed to fetch push payload for tag ${tag} with status ${response.status}.`,
      name: AppException.ERROR_NAME.PushPayloadFetchError,
      details: {
        status: response.status,
        url: response.url,
        origin: self.location.href,
      },
    });
  }

  let responseData;

  try {
    responseData = await response.json();
  } catch (error: any) {
    throw new AppException({
      message: `Parsing of the notification payload failed, ${error.message}`,
      name: AppException.ERROR_NAME.NotificationParseError,
      details: {
        status: response.status,
        url: response.url,
      },
    });
  }

  if (Array.isArray(responseData?.data) && responseData.data.length) {
    return responseData.data;
  }

  throw new AppException({
    message: `Received empty/invalid response from tag ${tag}.`,
    name: AppException.ERROR_NAME.InvalidNotificationPayload,
    details: { responseData, url: response.url, status: response.status },
  });
};
