import { sendHttpRequest } from '../libraries/httpRequest.library';
import AppException from '../exceptions/App.exception';
import { isObject, replaceLastNumberFromIpToZero, isEmptyObject } from '../libraries/app.library';
import { addQueryParamsToUrl } from '../libraries/url.library';
import { env } from '../config/constants.config';

type TAppDataParamSource = 'service-worker-amp' | 'service-worker' | 'sdk';

type TAppDataParams = { appId: string; source?: TAppDataParamSource; version?: number };

export const getAppData = async <T>({
  appId,
  source = 'sdk',
  version = 1,
}: TAppDataParams): Promise<T | null> => {
  if (!appId) {
    throw new AppException({
      message: AppException.ERROR_MESSAGE.InvalidAppId,
      name: AppException.ERROR_NAME.InvalidAppId,
    });
  }

  const url = addQueryParamsToUrl(`${env.__CDN_SERVER_HOST__}/v${version}/sdk-app-data/${appId}`, {
    source,
    sdkv: env.__SDK_VERSION__,
    swv: env.__WORKER_VERSION__,
  });

  const response = await sendHttpRequest(url, {
    retries: 4,
  });

  if (!response.ok) {
    const data: TAppDataErrorResponse = await response.json();

    throw new Error(`${data.message || 'Something went wrong'}`);
  }

  const data = await response.json();

  return isObject(data) ? data : null;
};

export const getGeoInfo = (() => {
  let cachedGeoInfo: TGeoInfo | null = null;

  return async ({ isEu }: { isEu: boolean }): Promise<TGeoInfo> => {
    if (cachedGeoInfo) {
      return cachedGeoInfo;
    }

    const url = addQueryParamsToUrl(`${env.__SERVER_HOST__}/geo-details`, {
      sdkv: env.__SDK_VERSION__,
      swv: env.__WORKER_VERSION__,
    });

    try {
      const response = await sendHttpRequest(url, {
        retries: 3,
      });

      if (!response.ok) {
        return {};
      }

      const data = await response.json();

      const geoInfo = isObject(data) ? data : {};

      if (!isEmptyObject(geoInfo)) {
        geoInfo.ipaddress = replaceLastNumberFromIpToZero(isEu, geoInfo.ipaddress);
      }

      cachedGeoInfo = geoInfo;

      return geoInfo;
    } catch (e) {
      return {};
    }
  };
})();
