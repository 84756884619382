import { sendHttpRequest } from '../libraries/httpRequest.library';
import { getTimezoneName, getTimezoneOffset } from '../libraries/dateTime.library';
import log from '../libraries/log.library';
import { setSubscriberDataToStorage } from '../utils/browserStorage.util';
import { addQueryParamsToUrl } from '../libraries/url.library';
import { isEmptyObject } from '../libraries/app.library';
import { getSubscriberDataFromStorageOrAPI, throwDexterAPIError } from './helper.api';
import { detectBrowser } from '../libraries/browser.library';
import AppException from '../exceptions/App.exception';
import { env } from '../config/constants.config';

type TSubscriberGeoInfo = {
  geobytestimezone?: string;
  geobytescity?: string;
  geobytescode?: string;
  geobytescountry?: string;
  geobytesfqcn?: string;
  geobytesinternet?: string;
  geobytesregion?: string;
  geobytesipaddress?: string;
};

const transformGeoInfo = (geoInfo?: TGeoInfo): TSubscriberGeoInfo => {
  const geoInfoPayload: TSubscriberGeoInfo = {
    geobytestimezone: geoInfo?.timezone || getTimezoneName() || getTimezoneOffset(),
  };

  if (!geoInfo) {
    return geoInfoPayload;
  }

  const keysToMap: (keyof TGeoInfo)[] = [
    'country',
    'internet',
    'region',
    'code',
    'city',
    'fqcn',
    'ipaddress',
  ];

  keysToMap.forEach((key: keyof TGeoInfo) => {
    if (geoInfo[key]) {
      geoInfoPayload[`geobytes${key}` as keyof TSubscriberGeoInfo] = geoInfo[key] as string;
    }
  });

  return geoInfoPayload;
};

const getTaboolaAdsUserId = async (): Promise<string | null> => {
  const url = `${env.__TABOOLA_API_ENDPOINT__}/json/pushengage1-new/user.sync?app.type=web&app.apikey=${env.__TABOOLA_API_KEY__}`;

  try {
    const response = await sendHttpRequest(url);

    if (response.ok && response.status === 200) {
      const responseData = await response.json();

      return responseData?.user?.id || null;
    }

    return null;
  } catch (error: any) {
    log.debug('Error occurred during getting taboola ads user id:', error);

    return null;
  }
};

export const getSubscriberData = async (subscriberId: string): Promise<TSubscriberData | null> => {
  let subscriberData = null;

  try {
    subscriberData = await getSubscriberDataFromStorageOrAPI(subscriberId);
  } catch (error) {
    log.debug('Error occurred:', error);
  }

  return subscriberData;
};

/**
 * NOTE: Please note that the document and window objects are not accessible
 * within a web worker.
 */
export const addSubscriberDataToSite = async ({
  browserInfo,
  site,
  subscription,
  options = {},
}: {
  browserInfo: TBrowserInfo;
  site: TSite;
  subscription:
    | {
        endpoint: string;
        project_id: string;
      }
    | (TWebPushPermissionData & {
        project_id: string;
        vapid_public_key: string;
      });
  options?: Partial<{
    geoInfo: TGeoInfo;
    optInType: TOptInType;
    tokenRefresh: boolean;
    isPublisherSettingEnabled: boolean;
    widgetOptInType: TWidgetOptInType;
  }>;
}): Promise<string> => {
  // Format the payload
  const transformedGeoInfo = transformGeoInfo(options.geoInfo);

  const payload: Record<string, any> = {
    subscription,
    site_id: site.site_id,
    browser_info: {
      device_type: browserInfo.name,
      browser_version: browserInfo.version.toString(),
      user_agent: browserInfo.userAgent,
      language: browserInfo.language,
      total_scr_width_height: browserInfo.screen,
      available_scr_width_height: browserInfo.availableScreen,
      colour_resolution: browserInfo.pixelDepth,
      host: browserInfo.host,
      device: browserInfo.device,
      pe_ref_url: browserInfo.referrer || browserInfo.href,
    },
    geo_info: transformedGeoInfo,
    subscription_url: browserInfo.href,
    token_refresh: options.tokenRefresh ?? false,
  };

  if (options.isPublisherSettingEnabled) {
    try {
      const adsUserId = await getTaboolaAdsUserId();

      if (adsUserId) {
        payload.tid = adsUserId;
      }
    } catch (error: any) {
      log.debug("Couldn't get Taboola Ads User ID", error);
    }
  }

  /**
   * The widgetType refers to the user's interaction with the recovery/
   * subscription management widget. If the widgetType is present, we assume
   * that the user subscribed through the widget rather than an opt-in/popup
   * modal. Both the widgetType and optInType can be present.
   */
  if (options.widgetOptInType) {
    payload.widget_optin_type = options.widgetOptInType;
  } else if (options.optInType) {
    payload.optin_type = options.optInType;
  }

  // Send the payload to the server
  const url = addQueryParamsToUrl(`${env.__SUBSCRIBER_API_ENDPOINT__}/subscriber/add`, {
    bv: browserInfo.version,
    swv: env.__WORKER_VERSION__,
  });

  const response = await sendHttpRequest(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    // Added higher retries because we are getting error from the server.
    retries: 4,
  });

  if (!response.ok) {
    await throwDexterAPIError(response, "Couldn't add subscriber.");
  }

  let data: TAddSubscriberAPIResponse;

  try {
    data = await response.json();
  } catch (error: any) {
    throw new AppException({
      message: `Error parsing response from add subscriber.`,
      name: AppException.ERROR_NAME.SubscriberAdditionServerError,
    });
  }

  if (data.error_code) {
    throw new AppException({
      message: `Couldn't add subscriber. Error code: ${data.error_code}.`,
      name: AppException.ERROR_NAME.SubscriberAdditionServerError,
    });
  }

  if (!data.data?.subscriber_hash) {
    throw new AppException({
      message: `Couldn't add subscriber. Subscriber hash is not available.`,
      name: AppException.ERROR_NAME.SubscriberHashNotAvailableServerError,
    });
  }

  return data.data.subscriber_hash;
};

export const syncSubscriberData = async ({
  browserInfo,
  geoInfo,
  subscriberId,
  isPublisherSettingEnabled,
}: {
  subscriberId: string;
  browserInfo: TBrowserInfo;
  geoInfo?: TGeoInfo;
  isPublisherSettingEnabled?: boolean;
}) => {
  const subscriberData = await getSubscriberDataFromStorageOrAPI(subscriberId);

  if (!subscriberData) {
    return;
  }

  const userAgent = navigator.userAgent;

  /**
   * Use the timezone name from geoInfo, If not available then use the browser
   * API if both are absent, resort to timezone offset.
   */
  const timezone = geoInfo?.timezone || getTimezoneName() || getTimezoneOffset();

  const payload: any = {};
  let isGeoInfoChanged = false;

  if (subscriberData.user_agent !== userAgent) {
    payload.browser_info = {
      user_agent: userAgent,
      browser_version: browserInfo.version.toString(),
      device_type: browserInfo.name,
      language: browserInfo.language,
      total_scr_width_height: browserInfo.screen,
      available_scr_width_height: browserInfo.availableScreen,
      colour_resolution: browserInfo.pixelDepth,
      device: browserInfo.device,
    };
  }

  if (
    geoInfo &&
    (subscriberData.city !== geoInfo.city ||
      subscriberData.timezone !== timezone ||
      subscriberData.country !== geoInfo.country ||
      subscriberData.state !== geoInfo.state)
  ) {
    payload.geo_info = transformGeoInfo(geoInfo);
    isGeoInfoChanged = true;
  }

  if (isPublisherSettingEnabled && (!subscriberData.tid || isGeoInfoChanged)) {
    const taboolaAdsUserId = await getTaboolaAdsUserId();

    payload.tid = taboolaAdsUserId;
  }

  if (isEmptyObject(payload)) {
    log.debug('The subscriber data is up to date.');

    return;
  }

  const url = addQueryParamsToUrl(`${env.__SUBSCRIBER_API_ENDPOINT__}/subscriber/${subscriberId}`, {
    swv: env.__WORKER_VERSION__,
    bv: browserInfo.version,
  });

  const response = await sendHttpRequest(url, {
    method: 'PUT',
    body: JSON.stringify(payload),
    retries: 2,
  });

  if (response.ok) {
    const updatedSubscriberData: TSubscriberData = {
      timezone,
      city: geoInfo?.city,
      country: geoInfo?.country,
      state: geoInfo?.region,
      user_agent: userAgent,
      device_type: browserInfo.name,
      device: browserInfo.device,
    };

    if (payload.tid) {
      updatedSubscriberData.tid = payload.tid;
    }

    setSubscriberDataToStorage(updatedSubscriberData);

    log.debug('The subscriber data has been updated in the storage.');
  }
};

export const updateSubscriberStatus = async ({
  status,
  subscriberId,
  siteId,
}: {
  subscriberId: string;
  status: 'subscribe' | 'unsubscribe';
  siteId: number;
}) => {
  const browserInfo = detectBrowser();

  const url = addQueryParamsToUrl(
    `${env.__SUBSCRIBER_API_ENDPOINT__}/subscriber/updatesubscriberstatus`,
    {
      swv: env.__WORKER_VERSION__,
      bv: browserInfo.version,
    },
  );

  const response = await sendHttpRequest(url, {
    method: 'POST',
    body: JSON.stringify({
      IsUnSubscribed: status === 'unsubscribe' ? 1 : 0,
      device_token_hash: subscriberId,
      site_id: siteId,
    }),
    retries: 3,
  });

  if (!response.ok) {
    await throwDexterAPIError(response, "Couldn't update subscriber status.");
  }
};

export const updateAutomatedNotificationStatus = async ({
  status,
  subscriberId,
  siteId,
}: {
  subscriberId: string;
  status: TAutomatedNotificationPayload;
  siteId: number;
}) => {
  const triggerStatus = status === 'disabled' ? 0 : 1;

  // Get subscriber data
  const subscriberData = await getSubscriberDataFromStorageOrAPI(subscriberId);

  if (subscriberData?.trigger_status === triggerStatus) {
    return;
  }

  // Update trigger status
  const browserInfo = detectBrowser();

  const url = addQueryParamsToUrl(
    `${env.__SUBSCRIBER_API_ENDPOINT__}/subscriber/updatetriggerstatus`,
    {
      swv: env.__WORKER_VERSION__,
      bv: browserInfo.version,
    },
  );

  const response = await sendHttpRequest(url, {
    method: 'POST',
    body: JSON.stringify({
      triggerStatus,
      device_token_hash: subscriberId,
      site_id: siteId,
    }),
    retries: 2,
  });

  if (!response.ok) {
    await throwDexterAPIError(response, "Couldn't update automated Notification status.");
  }

  // Store trigger data to storage
  setSubscriberDataToStorage({
    trigger_status: triggerStatus,
  });
};
