import * as workerManager from '../managers/serviceWorker.manager';
import log from '../libraries/log.library';
import { isWorker } from '../libraries/app.library';

(() => {
  // Check if service worker is supported.
  if (!isWorker()) {
    log.warn(
      'Service worker is not supported, please use a modern browser to support it (Chrome, Firefox, Opera, Edge, Safari).',
    );

    return;
  }

  // Declare the service worker scope.
  const scope: ServiceWorkerGlobalScope = self as any as ServiceWorkerGlobalScope;

  // Check if service worker is already imported.
  if (scope.isPushEngageSWAlreadyImported) {
    log.warn('Service worker is already imported.');

    return;
  }

  scope.isPushEngageSWAlreadyImported = true;

  /**
   * The service worker has been registered and installed on the page. This
   * event is triggered only once when the service worker is registered for
   * the first time or when the service worker file has changed (even if it's
   * just by one byte).
   */
  scope.addEventListener('install', workerManager.onServiceWorkerInstall);

  // Triggers when the ServiceWorker gains control over pages.
  scope.addEventListener('activate', workerManager.onServiceWorkerActivated);

  // The received push message.
  scope.addEventListener('push', workerManager.onPushReceived);

  // The user has click on the notification.
  scope.addEventListener('notificationclick', workerManager.onNotificationClick);

  // The user has close on the notification.
  scope.addEventListener('notificationclose', workerManager.onNotificationClose);

  // For AMP WebPush.
  scope.addEventListener('message', workerManager.onMessageReceived);
})();
