import AppException from '../exceptions/App.exception';
import { delay } from '../libraries/app.library';
import locker from '../libraries/locker.library';

const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
  // Decode the base64 string
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const global = typeof window !== 'undefined' ? window : self;
  const binaryString = global.atob(base64);

  // Convert the binary string to a Uint8Array
  const bytes = new Uint8Array(binaryString.length);

  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes;
};

const getWorkerRegistration = async ({
  scriptURL,
  options,
}: TServiceWorkerRegistrationPayload): Promise<ServiceWorkerRegistration> => {
  const newOptions = { ...options };
  let newScriptURL = scriptURL;

  // In this case, assume that the service worker is registered by the site itself.
  if (!scriptURL) {
    const registrations = await navigator.serviceWorker.getRegistrations();

    if (registrations.length === 0) {
      throw new AppException({
        message: 'The service worker is not registered and tries to get a subscription.',
        name: AppException.ERROR_NAME.ServiceWorkerNotFound,
      });
    }

    const [registration] = registrations;

    newScriptURL = registration.active?.scriptURL;

    if (!newScriptURL) {
      throw new AppException({
        message: 'The service worker scriptURL is missing in the registration object.',
        name: AppException.ERROR_NAME.ServiceWorkerNotFound,
      });
    }

    if (registration.scope === `${location.origin}/`) {
      newOptions.scope = '/';
    }
  }

  try {
    const serviceWorkerRegistration = await navigator.serviceWorker.register(
      newScriptURL as string,
      newOptions,
    );

    /**
     * This wait is necessary to ensure that the service worker is activated. If the
     * scope is not set to '/', the promise navigator.serviceWorker.ready may not
     * resolve.
     */
    if (!newOptions?.scope) {
      await delay(500);
    } else {
      await navigator.serviceWorker.ready;
    }

    return serviceWorkerRegistration;
  } catch (error: any) {
    throw new AppException({
      message: error.message,
      name: AppException.ERROR_NAME.ServiceWorkerNotRegistering,
    });
  }
};

export const requestNotificationPermission = async (): Promise<NotificationPermission> => {
  if (!('Notification' in window)) {
    throw new Error('This browser does not support notifications.');
  }

  /**
   * We never expect this error since we check the Notification object in the
   * SDK entry file. If a site overwrites this object, it will trigger an
   * error. We'll capture this in the error log, but we can't fix it.
   */
  if (!('requestPermission' in Notification)) {
    throw new AppException({
      message: 'This browser does not support requestPermission.',
      name: AppException.ERROR_NAME.RequestPermissionNotSupported,
    });
  }

  const permission = await Notification.requestPermission();

  if (permission === 'default' && Notification.permission === 'denied') {
    /**
     * Checking the Notification.permission status because if the user has
     * automatically denied permission, the returned permission status may
     * be different from the current status.
     */
    return 'denied';
  }

  return permission;
};

/**
 * Requests notification permission in Safari using the pushNotification API.
 *
 * @param {string} pushId - The website Push ID.
 * @param {string} pushPackageUrl - The URL to the push package.
 * @param {object} userData - User data to be sent to your server to help identify the user.
 * @param {string} userData.app_id - The app ID.
 * @returns {Promise} - A Promise that resolves with the permission status or rejects with an error message.
 */
export const requestSafariNotificationPermission = (
  pushId: string,
  pushPackageUrl: string,
  userData: {
    app_id: string;
  },
): Promise<TSafariPermissionData> => {
  return new Promise(function (resolve, reject) {
    if (!('safari' in window && 'pushNotification' in (<any>window).safari)) {
      reject(new Error('Safari push notifications are not supported.'));

      return;
    }

    const currentPermissionData: TSafariPermissionData = (<any>(
      window
    )).safari.pushNotification.permission(pushId);

    if (currentPermissionData.permission === 'default') {
      (<any>window).safari.pushNotification.requestPermission(
        pushPackageUrl,
        pushId,
        userData,
        function (permissionData: TSafariPermissionData) {
          resolve(JSON.parse(JSON.stringify(permissionData)));
        },
      );
    } else {
      resolve(JSON.parse(JSON.stringify(currentPermissionData)));
    }
  });
};

export const isServiceWorkerRegistered = async (): Promise<boolean> => {
  if (!('serviceWorker' in navigator)) {
    return false;
  }

  const registrations = await navigator.serviceWorker.getRegistrations();

  return registrations.length > 0;
};

export const subscribeToPushNotification = async ({
  vapidPublicKey,
  scriptURL,
  options,
}: {
  vapidPublicKey: string;
} & TServiceWorkerRegistrationPayload): Promise<TWebPushPermissionData> => {
  try {
    /**
     * This lock is necessary to prevent multiple subscription requests. For example,
     * if requests are sent in parallel every 10 milliseconds, it would create multiple
     * subscriptions without the use of a lock.
     */
    await locker.acquireLock('subscribeToPushNotification');

    const subscriptionOptions = {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
    };

    // Obtain the subscription through the service worker registration.
    const serviceWorkerRegistration = await getWorkerRegistration({
      scriptURL: scriptURL,
      options: options,
    });

    const subscription = await serviceWorkerRegistration.pushManager.subscribe(subscriptionOptions);

    return JSON.parse(JSON.stringify(subscription));
  } catch (error: any) {
    /**
     * We throw a custom error in this instance to prevent it from being logged
     * and sent to the server. Sometimes, errors such as "Registration failed -
     * push service error",  "Registration failed - could not connect to push
     * server" or, "Registration failed - permission denied" may arise.
     * These errors occur when the browser disables the connection to the
     * push server or doesn't allow subscriptions. For instance, this can
     * happen with the Brave browser or any other browser. In the case of
     * Brave, if you don't enable the "Use Google services for push messaging"
     * setting under Privacy and Security, you will encounter this error.
     */
    if (error.name === 'AbortError' || error.name === 'NotAllowedError') {
      throw new AppException({
        message: error.message,
        name: AppException.ERROR_NAME.DisabledPushSubscriptionError,
      });
    }

    throw error;
  } finally {
    // Release the lock
    locker.releaseLock('subscribeToPushNotification');
  }
};

export const getPushSubscription = async ({
  scriptURL,
  options,
}: TServiceWorkerRegistrationPayload): Promise<TWebPushPermissionData | null> => {
  const serviceWorkerRegistration = await getWorkerRegistration({
    scriptURL: scriptURL,
    options: options,
  });

  const subscription = await serviceWorkerRegistration.pushManager.getSubscription();

  if (!subscription) {
    return null;
  }

  return JSON.parse(JSON.stringify(subscription));
};

export const getSafariSubscription = (pushId: string): TSafariPermissionData => {
  const permissionData = (<any>window).safari.pushNotification.permission(pushId);

  return JSON.parse(JSON.stringify(permissionData));
};

export const unsubscribeFromPushSubscription = async (): Promise<void> => {
  const registrations = await navigator.serviceWorker.getRegistrations();

  for (let i = 0; i < registrations.length; i++) {
    const subscription = await registrations[i].pushManager.getSubscription();

    if (subscription) {
      await subscription.unsubscribe();
    }
  }
};

export const unregisterServiceWorker = async () => {
  const registrations = await navigator.serviceWorker.getRegistrations();

  for (let i = 0; i < registrations.length; i++) {
    await registrations[i].unregister();
  }
};

export const getDeviceTokenFromEndpoint = (endpoint: string): string => {
  let deviceToken = '';

  if (endpoint.indexOf('token=') > -1) {
    deviceToken = endpoint.slice(endpoint.search('token=') + 6);
  } else {
    deviceToken = endpoint.split('/')[endpoint.split('/').length - 1];
  }

  return deviceToken;
};

export const getSubscriptionFromWorker = async (): Promise<TWebPushPermissionData | null> => {
  try {
    const subscription = await (
      self as unknown as ServiceWorkerGlobalScope
    ).registration.pushManager.getSubscription();

    const subscriptionData = JSON.parse(JSON.stringify(subscription));

    if (subscriptionData.endpoint) {
      return subscriptionData;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const subscribeToPushNotificationFromWorker = async (
  publicKey: string,
): Promise<TWebPushPermissionData> => {
  const options = {
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(publicKey),
  };

  const subscription = await (
    self as unknown as ServiceWorkerGlobalScope
  ).registration.pushManager.subscribe(options);

  return JSON.parse(JSON.stringify(subscription));
};
