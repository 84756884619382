export const getTimezoneOffset = (): string => {
  const timezoneOffsetMinutes = new Date().getTimezoneOffset();
  const sign = timezoneOffsetMinutes > 0 ? '-' : '+';
  const hours = Math.floor(Math.abs(timezoneOffsetMinutes) / 60);
  const minutes = Math.abs(timezoneOffsetMinutes) % 60;
  const timezoneOffset = `${sign}${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}`;

  return timezoneOffset;
};

export const getTimezoneName = (): string => {
  let timezone = '';

  if (typeof Intl?.DateTimeFormat()?.resolvedOptions === 'function') {
    const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();

    timezone = resolvedOptions?.timeZone || '';
  }

  return timezone;
};

export const getUnixTime = (expirationDays?: number): number => {
  const expirationDate = expirationDays
    ? new Date(Date.now() + expirationDays * 24 * 60 * 60 * 1000)
    : new Date();

  return expirationDate.getTime();
};
