import { sendHttpRequest } from '../libraries/httpRequest.library';
import log from '../libraries/log.library';
import { detectBrowser } from '../libraries/browser.library';
import {
  getSubscriberDataFromStorage,
  addSubscriberDataToStorage,
} from '../utils/browserStorage.util';
import { addQueryParamsToUrl } from '../libraries/url.library';
import { isEmptyObject, isUndefined, capitalizeFirstLetter } from '../libraries/app.library';
import { env } from '../config/constants.config';
import AppException from '../exceptions/App.exception';
import locker from '../libraries/locker.library';

const transformSubscriberData = (subscriberData: TSubscriberData): TSubscriberData => {
  const transformedSubscriberData = {
    ...subscriberData,
    attributes: subscriberData.attributes || {},
    segments: subscriberData.segments || [],
    trigger_status: isUndefined(subscriberData.trigger_status) ? 1 : subscriberData.trigger_status,
  };

  return transformedSubscriberData;
};

/**
 * This function parses the error response from the Dexter API and throws an
 * AppException.
 */
export const throwDexterAPIError = async (res: Response, defaultErrMsg?: string) => {
  let errorMessage = defaultErrMsg || 'Something went wrong.';
  let details = {};

  try {
    const data = await res.json();

    if (data && data.error) {
      errorMessage = `${capitalizeFirstLetter(data.error?.message || data.error_message)}.`;
      details = data.error?.details || {};
    }
  } catch (error) {
    log.debug('Failed to parse json response', error);
  }

  /**
   * Here, we are throwing an AppException instead of an Error because we
   * need to send details.
   */
  throw new AppException({
    details,
    message: errorMessage,
    type: AppException.ERROR_TYPE.DexterServerError,
  });
};

export const getSubscriberDataFromStorageOrAPI = async (
  subscriberId: string,
): Promise<TSubscriberData | null> => {
  try {
    // If another request is already fetching data, wait for it to complete.
    await locker.acquireLock('getSubscriberDataFromStorageOrAPI');

    // Get subscriber data from storage
    const subscriberData = getSubscriberDataFromStorage<TSubscriberData>();

    if (subscriberData) {
      return subscriberData;
    }

    // Get subscriber data from API
    const browserInfo = detectBrowser();

    const url = addQueryParamsToUrl(
      `${env.__SUBSCRIBER_API_ENDPOINT__}/subscriber/${subscriberId}`,
      {
        swv: env.__WORKER_VERSION__,
        bv: browserInfo.version,
      },
    );

    const response = await sendHttpRequest(url, {
      retries: 3,
    });

    if (!response.ok) {
      log.debug('Response not ok:', response);

      return null;
    }

    const data = await response.json();

    if (data?.error_code) {
      log.debug('response of error:', data);

      return null;
    }

    if (isEmptyObject(data?.data)) {
      return null;
    }

    const subscriberDataFromAPI = transformSubscriberData(data?.data);

    addSubscriberDataToStorage(subscriberDataFromAPI);

    log.debug('The subscriber data is stored in the storage.');

    return subscriberDataFromAPI;
  } catch (error) {
    log.debug('Error occurred:', error);

    return null;
  } finally {
    // Unlock the lock
    locker.releaseLock('getSubscriberDataFromStorageOrAPI');
  }
};
