import { logDebug } from './helper';
import AppException from '../exceptions/App.exception';

const isCookieStorageAvailable =
  typeof document !== 'undefined' && typeof document.cookie !== 'undefined';

function isLocalStorageAvailable() {
  try {
    return (
      'localStorage' in window &&
      window['localStorage'] !== null &&
      'setItem' in localStorage &&
      'getItem' in localStorage &&
      'removeItem' in localStorage
    );
  } catch (e) {
    return false;
  }
}

export const getDataFromCookies = (key: string): string | null => {
  if (!isCookieStorageAvailable) {
    logDebug('Cookie storage is not available');

    return null;
  }

  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const [cookieKey, cookieValue] = cookies[i].split('=');

    if (cookieKey.trim() === key) {
      return decodeURIComponent(cookieValue.trim());
    }
  }

  return null;
};

export const setDataToCookies = <T>(key: string, value: T, expirationDays = 30): void => {
  if (!isCookieStorageAvailable) {
    logDebug('Cookie storage is not available');

    return;
  }

  const expirationDate = new Date();

  expirationDate.setDate(expirationDate.getDate() + expirationDays);

  const formattedValue: any = typeof value === 'object' ? JSON.stringify(value) : value;

  const cookieValue =
    encodeURIComponent(formattedValue) + '; expires=' + expirationDate.toUTCString();

  document.cookie = key + '=' + cookieValue + '; path=/';
};

export const deleteDataFromCookies = (key: string | string[]): void => {
  if (!isCookieStorageAvailable) {
    logDebug('Cookie storage is not available');

    return;
  }

  const expires = 'expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

  const keys = Array.isArray(key) ? key : [key];

  keys.forEach(item => {
    /**
     * This check prevents setting an empty cookie. Some browsers, like Firefox,
     * show a warning in the console "Some cookies are misusing the recommended 'SameSite' attribute".
     */
    if (getDataFromCookies(item)) {
      document.cookie = `${item}=; ${expires}`;
    }
  });
};

export const setDataToLocalStorage = <T>(key: string, value: T): void => {
  if (!isLocalStorageAvailable()) {
    throw new AppException({
      message: 'Local storage is not available',
      name: AppException.ERROR_NAME.LocalStorageNotAvailable,
    });
  }

  const formattedValue: any = typeof value === 'object' ? JSON.stringify(value) : value;

  localStorage.setItem(key, formattedValue);
};

export const getDataFromLocalStorage = (key: string) => {
  if (!isLocalStorageAvailable()) {
    throw new AppException({
      message: 'Local storage is not available',
      name: AppException.ERROR_NAME.LocalStorageNotAvailable,
    });
  }

  const value = localStorage.getItem(key);

  if (!value) {
    return null;
  }

  return value;
};

export const deleteDataFromLocalStorage = (key: string | string[]): void => {
  if (!isLocalStorageAvailable()) {
    throw new AppException({
      message: 'Local storage is not available',
      name: AppException.ERROR_NAME.LocalStorageNotAvailable,
    });
  }

  if (Array.isArray(key)) {
    key.forEach(item => {
      localStorage.removeItem(item);
    });
  } else {
    localStorage.removeItem(key);
  }
};
