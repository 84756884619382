/**
 * NOTE: We cannot use the 'store.add' method consistently throughout the file, as errors may occur
 * when this method is called simultaneously in parallel processes.
 */

import { database as DATABASE } from '../config/constants.config';
import * as database from '../libraries/indexedDB.library';
import { keepLastMaxElements } from '../libraries/app.library';

export const getAppId = async () => {
  const key = 'appId';
  const storeName = 'ids';

  const db = await database.openDB(DATABASE.name, DATABASE.version, DATABASE.stores);

  const dbAppId = await database.getObject<{ id: string; appId: string }>(db, storeName, key);

  return dbAppId?.appId || null;
};

export const syncAppId = async (appId: string) => {
  const key = 'appId';
  const storeName = 'ids';

  const db = await database.openDB(DATABASE.name, DATABASE.version, DATABASE.stores);

  await database.upsertObject(db, storeName, { id: key, appId });
};

export const updateNotificationTagByKey = async (
  notificationTag: string,
  key: 'viewedNotificationTags' | 'clickedNotificationTags',
) => {
  const storeName = 'ids';

  const db = await database.openDB(DATABASE.name, DATABASE.version, DATABASE.stores);

  const dbNotificationObject = await database.getObject<{
    id: string;
    notificationTags: string[];
  }>(db, storeName, key);

  const keepMaxNotificationCount = 5;
  const existingNotificationTags = dbNotificationObject?.notificationTags || [];

  await database.upsertObject(db, storeName, {
    id: key,
    notificationTags: keepLastMaxElements(
      [...existingNotificationTags, notificationTag],
      keepMaxNotificationCount,
    ),
  });
};

export const getNotificationTagsByKey = async (
  key: 'viewedNotificationTags' | 'clickedNotificationTags',
): Promise<string[]> => {
  const storeName = 'ids';

  const db = await database.openDB(DATABASE.name, DATABASE.version, DATABASE.stores);

  const dbNotificationObject = await database.getObject<{
    id: string;
    notificationTags: string[];
  }>(db, storeName, key);

  if (dbNotificationObject) {
    return dbNotificationObject.notificationTags || [];
  }

  return [];
};

export const addFailedViewedNotificationTag = async ({ tag }: { tag: string }) => {
  const storeName = 'failedViewedNotificationTags';

  const db = await database.openDB(DATABASE.name, DATABASE.version, DATABASE.stores);

  const dbNotificationObject = await database.getObject<{
    id: string;
  }>(db, storeName, tag);

  if (dbNotificationObject) {
    return;
  }

  await database.upsertObject(db, storeName, {
    id: tag,
  });
};

export const getFailedViewedNotificationTags = async () => {
  const storeName = 'failedViewedNotificationTags';

  const db = await database.openDB(DATABASE.name, DATABASE.version, DATABASE.stores);

  const dbNotificationObjects = await database.getObjects<
    {
      id: string;
    }[]
  >(db, storeName);

  return dbNotificationObjects;
};

export const deleteFailedViewedNotificationTag = async (notificationTag: string) => {
  const storeName = 'failedViewedNotificationTags';

  const db = await database.openDB(DATABASE.name, DATABASE.version, DATABASE.stores);

  await database.deleteObject(db, storeName, notificationTag);
};

export const addFailedClickedNotificationTag = async ({
  tag,
  action,
}: {
  tag: string;
  action: string;
}) => {
  const storeName = 'failedClickedNotificationTags';

  const db = await database.openDB(DATABASE.name, DATABASE.version, DATABASE.stores);

  const dbNotificationObject = await database.getObject<{
    id: string;
    action: string;
  }>(db, storeName, tag);

  if (dbNotificationObject) {
    return;
  }

  await database.upsertObject(db, storeName, {
    action,
    id: tag,
  });
};

export const getFailedClickedNotificationTags = async () => {
  const storeName = 'failedClickedNotificationTags';

  const db = await database.openDB(DATABASE.name, DATABASE.version, DATABASE.stores);

  const dbNotificationObjects = await database.getObjects<
    {
      id: string;
      action: string;
    }[]
  >(db, storeName);

  return dbNotificationObjects;
};

export const deleteFailedClickedNotificationTag = async (notificationTag: string) => {
  const storeName = 'failedClickedNotificationTags';

  const db = await database.openDB(DATABASE.name, DATABASE.version, DATABASE.stores);

  await database.deleteObject(db, storeName, notificationTag);
};
