const sleep = (delay: number) => new Promise(resolve => setTimeout(resolve, delay));

class Locker {
  private _locks: { [resourceName: string]: boolean };

  constructor() {
    this._locks = {};
  }

  async acquireLock(resourceName: string, sleepTime = 200) {
    while (this._locks[resourceName]) {
      await sleep(sleepTime);
    }

    this._locks[resourceName] = true;

    return true;
  }

  releaseLock(resourceName: string) {
    delete this._locks[resourceName];
  }

  isLocked(resourceName: string) {
    return !!this._locks[resourceName];
  }
}

const locker = new Locker();

export default locker;
