import log from '../libraries/log.library';
import * as workerHelper from '../helpers/serviceWorker.helper';
import { getSubscriptionFromWorker, getDeviceTokenFromEndpoint } from '../utils/subscription.util';
import { sendErrorLog } from '../api/errorLog.api';
import { workerMessengerCommand, env } from '../config/constants.config';

/**
 * NOTE: This async function will never throw an error.
 */
const sendMessagesToAllClients = async (message: any) => {
  (self as unknown as { clients: Clients }).clients
    .matchAll()
    .then(clientList => {
      clientList.forEach(client => {
        client.postMessage(message);
      });
    })
    .catch((error: any) => {
      log.error(error.message);
    });
};

export const onServiceWorkerInstall = (event: ExtendableEvent) => {
  /**
   * skip waiting for old service worker control out of clients for activate new
   * service worker
   */
  event.waitUntil((self as unknown as ServiceWorkerGlobalScope).skipWaiting());
};

export const onServiceWorkerActivated = (event: ExtendableEvent) => {
  log.info(`The Service Worker has been activated (version ${env.__WORKER_VERSION__}).`);

  /**
   * AMP WebPush (amp-web-push v0.1) requires the usage of clients.claim() in the
   * ServiceWorker to ensure complete control over the iframe. Although this
   * requirement may be subject to change in the future based on AMP's decisions,
   * it is currently necessary. Failure to use clients.claim() may result in the
   * subscribe button not immediately updating after granting notification
   * permission from the pop-up in AMP.
   */
  event.waitUntil((self as unknown as { clients: Clients }).clients.claim());
};

export const onPushReceived = (event: PushEvent) => {
  const handleOnPushReceived = async () => {
    const pushEvent = event;

    // Get subscription
    let subscription;

    try {
      subscription = await getSubscriptionFromWorker();

      if (!subscription) {
        log.error('No subscription found for the PushEngage Service Worker.');

        return;
      }
    } catch (error: any) {
      log.error(error.message || 'Failed to get subscription.');

      sendErrorLog('service-worker', error, {
        url: self.location.href,
      });

      return;
    }

    // Show notification
    try {
      const deviceToken = getDeviceTokenFromEndpoint(subscription.endpoint);

      const notifications = await workerHelper.parseOrFetchNotifications(pushEvent, deviceToken);

      const formattedNotifications = workerHelper.formatNotifications(notifications);

      const promises = [];

      for (let i = 0; i < formattedNotifications.length; i++) {
        const notification = formattedNotifications[i];

        promises.push(
          workerHelper.showNotification({
            subscription,
            title: notification.title,
            notificationOptions: notification.options,
          }),
        );

        // Send notification displayed event to web page
        promises.push(
          sendMessagesToAllClients({
            data: { title: notification.title },
            eventName: 'PushEngage.notification.displayed',
          }),
        );
      }

      await Promise.all(promises);
    } catch (error: any) {
      log.error(error.message || 'Failed to show notification.');

      sendErrorLog('service-worker', error, {
        url: self.location.href,
      });

      try {
        await workerHelper.showDefaultNotification({ subscription });
      } catch (defaultNotificationError: any) {
        log.error(defaultNotificationError.message || 'Failed to show notification.');

        sendErrorLog('service-worker', error, {
          url: self.location.href,
        });
      }
    }
  };

  event.waitUntil(handleOnPushReceived());
};

export const onNotificationClick = (event: NotificationEvent) => {
  const handleOnNotificationClicked = async () => {
    const notificationEvent = event as NotificationEvent;

    try {
      // Close notification after click
      notificationEvent.notification.close();

      // Get notification Url
      const { userAction, notificationUrl } = workerHelper.getNotificationUrlAndUserAction(
        notificationEvent.notification.data,
        notificationEvent.action,
      );

      // Handle notification open url
      await workerHelper.handleNotificationOpenUrl(notificationUrl);

      // Send notification click event to web page
      sendMessagesToAllClients({
        data: {
          title: notificationEvent.notification.title,
          userAction:
            userAction === 'action1'
              ? 'button_1'
              : userAction === 'action2'
              ? 'button_2'
              : 'notification',
        },
        eventName: 'PushEngage.notification.click',
      });

      // Handle notification click
      const subscription = await getSubscriptionFromWorker();

      if (subscription) {
        await workerHelper.handleNotificationClick({
          userAction,
          subscription,
          notificationOptions: notificationEvent.notification,
        });
      }
    } catch (error: any) {
      log.error(error.message || 'Failed to handle notification click.');

      sendErrorLog('service-worker', error, {
        url: self.location.href,
        tag: notificationEvent.notification.data.originalTag,
      });
    }
  };

  // Handle notification click
  event.waitUntil(handleOnNotificationClicked());
};

export const onNotificationClose = (event: NotificationEvent) => {
  const notification = event.notification;

  // Send notification close event to web page
  sendMessagesToAllClients({
    data: {
      title: notification.title,
    },
    eventName: 'PushEngage.notification.close',
  });
};

export const onMessageReceived = (event: ExtendableMessageEvent) => {
  const handleOnMessageReceived = async () => {
    const data: TAMPMessengerMessage = event.data;

    if (typeof data?.command !== 'string' || !data.command.includes('amp-web-push')) {
      return;
    }

    try {
      switch (data.command) {
        // This used to request the current subscription state.
        case workerMessengerCommand.ampSubscriptionState:
          await workerHelper.handleMessageSubscriptionState();
          break;

        /**
         * This function is used to request the service worker to subscribe the user to
         * push notifications, assuming that the permissions for push notifications
         * have already been granted at this point.
         */
        case workerMessengerCommand.ampSubscribe:
          await workerHelper.handleMessageSubscribeState();
          break;

        // This used to request the current unsubscription state.
        case workerMessengerCommand.ampUnsubscribe:
          await workerHelper.handleMessageUnsubscribeState();
          break;
        default:
          return;
      }
    } catch (error: any) {
      log.error(error.message);

      sendErrorLog('service-worker', error, {
        url: self.location.href,
      });
    }
  };

  event.waitUntil(handleOnMessageReceived());
};
