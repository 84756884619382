import {
  getDataFromLocalStorage,
  setDataToLocalStorage,
  deleteDataFromLocalStorage,
  deleteDataFromCookies,
} from '../libraries/browserStorage.library';
import { storageName } from '../config/constants.config';
import { omit, get, isEmptyObject, toInteger, safeParse } from '../libraries/app.library';
import { getUnixTime } from '../libraries/dateTime.library';

export const getParsedStorageData = (storageName: string): TPushEngageDataInLocalStorage => {
  const storageData = getDataFromLocalStorage(storageName);

  return storageData ? safeParse<TPushEngageDataInLocalStorage>(storageData) || {} : {};
};

export const clearSubscriptionDeniedDataFromStorage = () => {
  const parsedStorageData = getParsedStorageData(storageName);
  const omittedDeniedStorageData = omit(parsedStorageData, ['denied']);

  if (isEmptyObject(omittedDeniedStorageData)) {
    deleteDataFromLocalStorage(storageName);
  } else {
    setDataToLocalStorage(storageName, omittedDeniedStorageData);
  }
};

export const clearOldSubscriptionDataFromStorage = () => {
  let storageData = getParsedStorageData(storageName);

  storageData = omit(storageData, ['id', 'isSubDomain', 'endpoint', 'subscriber']);

  if (isEmptyObject(storageData)) {
    deleteDataFromLocalStorage(storageName);
  } else {
    setDataToLocalStorage(storageName, storageData);
  }
};

export const clearSubscriptionUnsubscribedDataFromStorage = () => {
  let storageData = getParsedStorageData(storageName);

  storageData = omit(storageData, ['unsubscribed']);

  if (isEmptyObject(storageData)) {
    deleteDataFromLocalStorage(storageName);
  } else {
    setDataToLocalStorage(storageName, storageData);
  }

  deleteDataFromCookies('PushSubscriberStatus');
};

export const isSubscriptionOptInClosed = (optInId: TOptInType | TOptInType[]) => {
  const storageData = getParsedStorageData(storageName);

  if (!storageData.optInClosed) {
    return false;
  }

  const optInClosedData = storageData.optInClosed;
  const optInIds = Array.isArray(optInId) ? optInId : [optInId];

  for (let i = 0; i < optInIds.length; i++) {
    const data = get(optInClosedData, `${optInIds[i]}`);

    if (!data) {
      return false;
    }

    const { data: closed, expiresAt } = data;

    if (!closed || expiresAt <= getUnixTime()) {
      return false;
    }
  }

  return true;
};

export const hasSubscriptionDataInStorage = ({
  notificationPermission,
}: {
  notificationPermission: NotificationPermission;
}) => {
  const storageData = getParsedStorageData(storageName);

  /**
   * If the user has subscribed to the subdomain, then we don't need to check
   * notification permission.
   */
  const isPermissionGranted = storageData.isSubDomain || notificationPermission === 'granted';

  if (storageData.endpoint && storageData.id && isPermissionGranted) {
    return true;
  }

  return false;
};

export const isSubscriptionUnsubscribed = ({
  notificationPermission,
}: {
  notificationPermission: NotificationPermission;
}) => {
  const storageData = getParsedStorageData(storageName);

  if (!storageData.unsubscribed) {
    return false;
  }

  const { data: unsubscribedData, expiresAt } = storageData.unsubscribed;

  if (expiresAt <= getUnixTime()) {
    return false;
  }

  if (unsubscribedData.isSubDomain === false && notificationPermission !== 'granted') {
    clearSubscriptionUnsubscribedDataFromStorage();

    return false;
  }

  return unsubscribedData.status === true;
};

/**
 * NOTE: This function only checks the denied status from storage, not from
 * the browser permission. This is because we support three subscription
 * methods (http, https, and https with subdomains).
 */
export const isSubscriptionDenied = ({
  notificationPermission,
}: {
  notificationPermission: NotificationPermission;
}) => {
  const storageData = getParsedStorageData(storageName);

  if (!storageData.denied) {
    return false;
  }

  const { data: permissionDeniedData, expiresAt } = storageData.denied;

  if (expiresAt <= getUnixTime()) {
    return false;
  }

  if (permissionDeniedData.isSubDomain === false && notificationPermission !== 'denied') {
    clearSubscriptionDeniedDataFromStorage();

    return false;
  }

  return permissionDeniedData.status === true;
};

export const storeSubscriptionDataInStorage = ({
  subscriberId,
  subscription,
  isSubDomain,
}: {
  subscriberId: string;
  subscription: TSafariPermissionData | TWebPushPermissionData;
  isSubDomain: boolean;
}) => {
  const storageData: any = {
    isSubDomain,
    id: subscriberId,
    endpoint:
      (<TWebPushPermissionData>subscription).endpoint ||
      (<TSafariPermissionData>subscription).deviceToken,
  };

  setDataToLocalStorage(storageName, storageData);

  /**
   * Clear the legacy cookie storage data if it exists, as we have already stored
   * user data in the localStorage with a new format. This will avoid any conflicts
   * between the old and new storage data.
   */
  deleteDataFromCookies(['PushSubscriberStatus', 'peclosed']);
};

export const storeOptInCloseDataInStorage = ({
  optInType,
  storageDuration,
}: {
  optInType: TOptInType;
  storageDuration: number;
}) => {
  const storageData = getParsedStorageData(storageName);

  const updatedStorageData: TPushEngageDataInLocalStorage = {
    optInClosed: {
      ...storageData.optInClosed,
      [optInType]: {
        data: true,
        expiresAt: getUnixTime(storageDuration),
      },
    },
  };

  if (storageData?.reminderPrompt) {
    updatedStorageData.reminderPrompt = storageData.reminderPrompt;
  }

  setDataToLocalStorage(storageName, updatedStorageData);
};

export const storeSubscriptionDeniedDataInStorage = ({
  isSubDomain,
  storageDuration,
}: {
  isSubDomain: boolean;
  storageDuration: number;
}) => {
  const storageData = getParsedStorageData(storageName);

  const updatedStorageData: TPushEngageDataInLocalStorage = {
    denied: {
      data: { status: true, isSubDomain },
      expiresAt: getUnixTime(storageDuration),
    },
  };

  if (storageData.reminderPrompt) {
    updatedStorageData.reminderPrompt = storageData.reminderPrompt;
  }

  setDataToLocalStorage(storageName, updatedStorageData);

  /**
   * Clear the legacy cookie storage data if it exists, as we have already stored
   * user data in the localStorage with a new format. This will avoid any conflicts
   * between the old and new storage data.
   */
  deleteDataFromCookies(['PushSubscriberStatus', 'peclosed']);
};

export const storeSubscriptionUnsubscribedDataInStorage = (isSubDomain: boolean) => {
  const storageData = getParsedStorageData(storageName);

  const expirationDays = 365;

  const updatedStorageData: TPushEngageDataInLocalStorage = {
    unsubscribed: {
      data: { status: true, isSubDomain },
      expiresAt: getUnixTime(expirationDays),
    },
  };

  if (storageData.reminderPrompt) {
    updatedStorageData.reminderPrompt = storageData.reminderPrompt;
  }

  setDataToLocalStorage(storageName, updatedStorageData);

  /**
   * Clear the legacy cookie storage data if it exists, as we have already stored
   * user data in the localStorage with a new format. This will avoid any conflicts
   * between the old and new storage data.
   */
  deleteDataFromCookies(['PushSubscriberStatus', 'peclosed']);
};

export const getSubscriberIdFromStorage = (): string | null => {
  const storageData = getParsedStorageData(storageName);

  if (!storageData.id) {
    return null;
  }

  return storageData.id;
};

export const getSubscriberDataFromStorage = <T>(key?: keyof TSubscriberData): T | null => {
  const storageData = getParsedStorageData(storageName);

  const subscriberData = storageData.subscriber;

  if (!subscriberData || subscriberData.expiresAt <= getUnixTime()) {
    return null;
  }

  if (!key) {
    return subscriberData.data as T;
  }

  return (subscriberData.data[key] as T) || null;
};

export const addSubscriberDataToStorage = (subscriberData: TSubscriberData) => {
  const storageData = getParsedStorageData(storageName);

  const updatedStorageData: TPushEngageDataInLocalStorage = {
    ...storageData,
    subscriber: {
      expiresAt: getUnixTime(1),
      data: subscriberData,
    },
  };

  setDataToLocalStorage(storageName, updatedStorageData);
};

export const setSubscriberDataToStorage = (subscriberData: TSubscriberData) => {
  const storageData = getParsedStorageData(storageName);

  const expiresAt = storageData.subscriber?.expiresAt || getUnixTime(1);

  const updatedStorageData: TPushEngageDataInLocalStorage = {
    ...storageData,
    subscriber: {
      expiresAt,
      data: { ...storageData?.subscriber?.data, ...subscriberData },
    },
  };

  setDataToLocalStorage(storageName, updatedStorageData);
};

export const deleteSubscriberDataFromStorage = () => {
  const storageData = getParsedStorageData(storageName);

  if (!storageData.subscriber) {
    return;
  }

  const updatedStorageData = omit(storageData, ['subscriber']);

  if (isEmptyObject(storageData)) {
    deleteDataFromLocalStorage(storageName);
  } else {
    setDataToLocalStorage(storageName, updatedStorageData);
  }
};

export const getShopifyCampaignDataFromStorage = ():
  | TPushEngageDataInLocalStorage['shopify']
  | null => {
  const storageData = getParsedStorageData(storageName);

  if (!storageData.shopify) {
    return null;
  }

  return storageData.shopify;
};

export const setShopifyCampaignDataToStorage = (
  campaignData: TPushEngageDataInLocalStorage['shopify'],
): void => {
  const storageData = getParsedStorageData(storageName);

  const updatedStorageData: TPushEngageDataInLocalStorage = {
    ...storageData,
    shopify: {
      ...storageData?.shopify,
      ...campaignData,
    },
  };

  setDataToLocalStorage(storageName, updatedStorageData);
};

export const getReminderPromptDataFromStorage = ():
  | TPushEngageDataInLocalStorage['reminderPrompt']
  | null => {
  const storageData = getParsedStorageData(storageName);

  if (!storageData.reminderPrompt) {
    return null;
  }

  return storageData.reminderPrompt;
};

export const setReminderPromptDataToStorage = () => {
  const storageData = getParsedStorageData(storageName);

  const updatedStorageData: TPushEngageDataInLocalStorage = {
    reminderPrompt: {
      count: toInteger(storageData.reminderPrompt?.count) + 1,
      lastShownTime: getUnixTime(),
    },
  };

  // These data will be available along with the reminder prompt.
  if (storageData?.denied) {
    updatedStorageData.denied = storageData.denied;
  }

  if (storageData?.unsubscribed) {
    updatedStorageData.unsubscribed = storageData.unsubscribed;
  }

  if (storageData?.optInClosed) {
    updatedStorageData.optInClosed = storageData.optInClosed;
  }

  setDataToLocalStorage(storageName, updatedStorageData);
};

export const isSubscriptionOnSubDomain = () => {
  const storageData = getParsedStorageData(storageName);

  return storageData.isSubDomain;
};
